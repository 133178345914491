import React, { Component } from 'react';
import styled from 'styled-components';
import { Box, Title2, Title3, Large, colors } from 'js-components';
import { Section, WrapperBackground, WrapperSolid } from '../../components/Wrapper';
import PercentLinks from '../../components/PercentLinks';
import { Helmet } from 'react-helmet';
import { PageTitle, PageTitle2, PageTitle3 } from '../../components/PageTitle';

import ConversationCloud from '../images/conversationCloudDiagram.svg';

import LayerConnector from '../images/LayerConnector.svg';
import LayerGlobal from '../images/layerGlobal.svg';
import LayerTelephony from '../images/layerTelephony.svg';
import LayerWorkflow from '../images/layerWorkflow.svg';
import ActionEvent from '../images/actionEvent.svg';
import { CallToAction, GetStarted } from '../../components/CallToAction'


import Default from '../../components/Layouts/Default';


export default class RevenueProcessAutomation extends Component {

  render() {
    return (
      <Default>
        <Helmet>
          <meta charset="UTF-8" />
          <title>Truly | What is Sales Hyperautomation?</title>
          <meta
            name="description"
            content="Get the right message to the right prospect at the right time."
          />
        </Helmet>

        <WrapperBackground color={colors.trulyDark} background="boardroom"  gradientType="purpleToGreen">
          <Section flexDirection="row" py={['6rem', '7rem']} background={colors.trulyDark}>
            <Box width="1">
              <PageTitle2 as="h2" color={colors.accentFog} bold mb="1.5rem" center>
                What Is Sales HyperAutomation?
              </PageTitle2>
              <PageTitle color={colors.white} mb="1.5rem" center>
                Automate Away The Tasks No One Likes.  Improve Sales Efficiency and Customer Experience for Less.
              </PageTitle>
            </Box>
          </Section>
        </WrapperBackground>

        <WrapperSolid>
          <Section flexDirection="column" py={['6rem', '3rem']} background={colors.trulyDark}>
            <Box width={[1, 2 / 3]}>
              <PageTitle2 as="h2" color={colors.trulyBlue} bold my="1.5rem">
                An Introduction
              </PageTitle2>
              <Large as="p" color={colors.trulyDark} mb="1.5rem">
              Gartner named Hyperautomation a top trend to watch every year since 2020, predicting the overall market to be $500B+ by 2025.
              <br/><br/>
              It is “one of the fastest growing markets of all time”, yet nobody in revenue org is talking about it.  How do we know this?  Just google ‘sales hyperautomation’ and you’ll find almost no articles, no vendors, no ads… nothing.
              <br/><br/>
              Why is that?
              <br/><br/>
              In this article, we’ll unpack what hyperautomation is, why it’s so new to the revenue org and what benefits it can bring to companies that are increasingly focusing on driving revenue efficiency through Revenue Operations.
              </Large>
            </Box>
            <PageTitle2 as="h2" color={colors.trulyBlue} bold my="1.5rem">
                Sales Has Fallen Behind
              </PageTitle2>
            <br/><br/><br/>
            <Large as="p" color={colors.trulyDark} mb="1.5rem">
            According to a McKinsey report, sales has fallen behind all other functions in automation by a factor of 3x.
            <br/><br/>
Over the same time as this McKinsey report, the salesforce automation market grew from almost nothing to $10B+ annually. Furthermore, Gartner survey respondents said they were planning to increase spend in automation software by 20% in 2022, despite the turbulent economics environment.

            <br/><br/>
So if sales is investing in automation software at a blistering pace, how is it that it still lags all other functions so heavily in automation?

            <br/><br/>
To understand, let’s take a look at what is and isn’t being automated on the sales floor today.
            </Large>
            <PageTitle2 as="h2" color={colors.trulyBlue} bold my="1.5rem">
                What Is Basic Automation?
              </PageTitle2>
            <Large as="p" color={colors.trulyDark} mb="1.5rem">
            </Large>


           <PageTitle2 as="h2" color={colors.trulyBlue} bold my="1.5rem">
                Our Approach:
                <br/> 
                No-Code Data Mining
            </PageTitle2>
            <br/>
            <Box width={[1, 3/3]} mb={['32px', 0]}>
              <CenteredImg src={ActionEvent} alt="Salesforce process automation"/>

            </Box>
            <br/><br/><br/>
            <Box width={[1, 2/3]} mb={['32px', 0]}>
 
              <Large as="p" color={colors.trulyDark} mb="1.5rem">
                Truly's bots give you a new class of metadata for both activities (calls,texts,meetings,emails) and third party data (Linkedin).
                <br/><br/>
                Robots then let you analyze that content at scale using a combination of AI and no-code automation to segment your accounts and contacts (eg: 'Show me VPs of Sales who used to be in Customer Success and talk about NPS in their discovery call.')
                <br/><br/>
                This entire process takes place directly inside the CRM and can be consumed by any marketing automation platform, sales engagement platform or other tool of your choice.
              </Large>
            </Box>

            <br/><br/><br/>
          </Section>

        </WrapperSolid>

       <WrapperBackground background="wave" gradientType="dark">
        <PercentLinks/>
      </WrapperBackground>

        <WrapperBackground background="headsetPerson1" gradientType="purple">
          <CallToAction/>
        </WrapperBackground>
        <WrapperBackground background="sf" gradientType="black">
          <GetStarted/>
        </WrapperBackground>
      </Default>
    );
  }
}

const Values = styled.div`
  background-color: #222;
  font-family: 'Josefin Sans';
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.3);
`;

const CenteredImg = styled.img`
  display: block;
  max-width: 100%;
  margin: 0 auto;
`;

const WidthImg = styled.img`
  display: block;
  max-width: 100%;
  margin: 0 auto;
`;
